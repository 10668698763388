import React from "react"

import "../styles/index.css"
import "../styles/utilities.css"
import "../styles/contact.css"

import Navbar from "../components/navbar"
import Footer from "../components/footer"
import SEO from "../components/seo"

const Contact = ({ data, location }) => {
  const title = "Contact Us"
  const description =
    "Contact our Church! Say hello to our minister Boy Golden! Get in contact with our church management and our record label (Six Shooter Records)."

  return (
    <>
      <SEO title={title} description={description} />
      <div className="contact-page-container ">
        <Navbar />
        <div className="content-container contact-shadow ">
          <form
            name="contact"
            method="POST"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <input type="hidden" name="form-name" value="contact" />

            <div className="form-container bg-white tacky-border1 bg-mandala">
              <h1 className="txt-secondary center-text txt-shadow2">
                Send Us A Message
              </h1>
              <br />

              <div className="flex-labels">
                <label htmlFor="name">Name *</label>{" "}
                <input
                  id="name"
                  type="text"
                  name="name"
                  maxlength="100"
                  required
                />
              </div>
              <div className="flex-labels">
                <label htmlFor="email">Email * </label>
                <input
                  id="email"
                  type="email"
                  name="email"
                  maxlength="100"
                  required
                />
              </div>
              <div className="flex-labels">
                <label htmlFor="subject">Subject * </label>
                <input
                  id="subject"
                  name="subject"
                  rows="1"
                  cols="40"
                  maxlength="100"
                  required
                ></input>
              </div>
              <div className="flex-labels">
                <label htmlFor="message">Message *</label>
                <textarea
                  id="message"
                  className="message-box"
                  name="message"
                  rows="8"
                  cols="60"
                  maxlength="2000"
                  required
                ></textarea>
              </div>
              <p>
                <button id="mailing-list" type="submit">
                  Send
                </button>
              </p>
            </div>
          </form>
          <br />

          <br />

          <div className="form-container bg-white tacky-border3 bg-mandala">
            <h3 className="txt-secondary center-text txt-shadow2">
              Management:
            </h3>
            <div className="contact-subtext">
              <h5 className="txt-highlight txt-shadow2">
                Watchdog Management:{" "}
              </h5>
              <a href="mailto:info@watchdogmgt.com">
                <h5 className="txt-alt ">info@watchdogmgt.com</h5>{" "}
              </a>
            </div>
            <br />
            <h3 className="txt-secondary center-text txt-shadow2">
              Label / Publicity Contacts:
            </h3>

            <div className="contact-subtext">
              <div>
                <h5 className="txt-highlight txt-shadow2">
                  Publicity Contact:{" "}
                </h5>
                <a href="mailto: emilysmart@sixshooterrecords.com">
                  <h5 className="txt-alt ">emilysmart@sixshooterrecords.com</h5>{" "}
                </a>
              </div>
              <div>
                <h5 className="txt-highlight txt-shadow2">
                  Sync & Licensing:{" "}
                </h5>

                <a href="mailto: its.boy.golden@gmail.com">
                  <h5 className="txt-alt ">its.boy.golden@gmail.com</h5>{" "}
                </a>
              </div>
              <div>
                <h5 className="txt-highlight txt-shadow2">General: </h5>
                <a href="mailto: info@sixshooterrecords.com">
                  <h5 className="txt-alt ">info@sixshooterrecords.com</h5>{" "}
                </a>
              </div>
              <div>
                <h5 className="txt-highlight txt-shadow2">Label: </h5>
                <a
                  href="https://sixshooterrecords.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <h5 className="txt-alt ">www.sixshooterrecords.com</h5>{" "}
                </a>
              </div>
              <div>
                <div className="contact-subtext">
                  <div>
                    <h5 className="txt-highlight txt-shadow2">Agents: </h5>
                    <h5 className="txt-highlight txt-shadow2">
                      Jeremy Giacomin - Paquin Artists Agency (Canada)
                    </h5>{" "}
                    <a href="mailto: jeremy@paquinartistsagency.com">
                      <h5 className="txt-alt">
                        {" "}
                        jeremy@paquinartistsagency.com
                      </h5>{" "}
                    </a>
                    <h5 className="txt-highlight txt-shadow2">
                      Dan Kuklinski - William Morris Entertainment (US)
                    </h5>{" "}
                    <a href="mailto: dkuklinski@wmeagency.com">
                      <h5 className="txt-alt"> dkuklinski@wmeagency.com</h5>{" "}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  )
}

export default Contact
